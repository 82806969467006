<!--  -->
<template>
<div class="filePage">
    <div class="protocol-head">
        <div class="protocol-head-container flex-vc flex">
            <a href="/" class="flex-vc flex">
                <div class="logo">
                    <img src="../../assets/img/default/upupoo.png">
                </div>
                <span class="text">upupoo</span>
            </a>
        </div>
    </div>
    <div class="protocol-layout-body">
        <h3>《UPUPOO用户协议》</h3>
        <p class="protocal-title-bold">1、引言</p>
        <p>欢迎使用UPUPOO软件及相关服务！</p>
        <p>“UPUPOO”软件及相关服务，系指公司通过合法拥有并运营的、标注名称为“UPUPOO”的客户端应用程序以及“UPUPOO”（upupoo.com）的网站，向您提供的产品与服务，包括但不限于文字、图片、视频发布、浏览及推荐等功能，以及连接人与信息的新型服务。《UPUPOO用户协议》（以下简称“本协议”）是您与公司之间就您注册、登录、使用（以下统称“使用”）“UPUPOO”软件及相关服务所订立的协议。</p>
        <p>为了更好地为您提供服务，请您在开始使用“UPUPOO”软件及相关服务之前，务必认真阅读并充分理解本协议，特别是涉及免除或者限制责任的条款、权利许可和信息使用的条款、同意开通和使用特殊单项服务的条款、法律适用和争议解决条款等。&nbsp;其中，免除或者限制责任条款等重要内容将以加粗形式提示您注意，您应重点阅读。如您未满18周岁，请您在法定监护人陪同下仔细阅读并充分理解本协议，并征得法定监护人的同意后使用“UPUPOO”软件及相关服务。</p>
        <p>除非您接受本协议的全部内容，否则您将无法正常注册、登录、使用（以下统称“使用”）“UPUPOO”软件及相关服务。一旦您使用“UPUPOO”软件及相关服务，则视为您已充分理解本协议，并同意作为本协议的一方当事人接受本协议以及其他与“UPUPOO”软件及相关服务相关的协议和规则（包括但不限于《隐私协议》）的约束。</p>
        <p class="protocal-title-bold">2、 “UPUPOO”软件及相关服务</p>
        <p>2.1 您使用“UPUPOO”软件及相关服务，可以通过预装、公司已授权的第三方下载等方式获取“UPUPOO”客户端应用程序或访问UPUPOO（upupoo.com）相关网站。&nbsp;若您并非从公司或经公司授权的第三方获取本软件的，公司无法保证非官方版本的“UPUPOO”软件能够正常使用，您因此遭受的损失与公司无关。</p>
        <p>2.2 公司可能为不同的终端设备开发了不同的应用程序软件版本，您应当根据实际设备状况获取、下载、安装合适的版本。如您不再使用“UPUPOO”软件及相关服务，您也可自行卸载相应的应用程序软件。</p>
        <p>2.3 为更好的提升用户体验及服务，公司将不定期提供“UPUPOO”软件及相关服务的更新或改变（包括但不限于软件修改、升级、功能强化、开发新服务、软件替换等），您可根据自行需要更新相应的版本。</p>
        <p>为保证“UPUPOO”软件及相关服务安全、提升用户服务，在“UPUPOO”软件及相关服务的部分或全部更新后，公司将在可行的情况下以妥当的方式（包括但不限于系统提示、公告、站内信等）提示您，您有权选择接受更新后的版本；如您选择不作更新，“UPUPOO”软件及相关服务的部分功能将受到限制或不能正常使用。</p>
        <p>2.4 除非得到公司事先书面授权，您不得以任何形式对“UPUPOO”软件及相关服务进行包括但不限于改编、复制、传播、垂直搜索、镜像或交易等未经授权的访问或使用。</p>
        <p>2.5 您理解，使用“UPUPOO”软件及相关服务需自行准备与软件及相关服务有关的终端设备（如电脑、手机等），一旦您在终端设备中打开“UPUPOO”软件或访问“UPUPOO” （upupoo.com），即视为您使用“UPUPOO”软件及相关服务。为充分实现“UPUPOO”的全部功能，您可能需要将终端设备联网，您理解由您承担所需要的费用（如流量费、上网费等）。</p>
        <p>2.6 公司许可您一项个人的、不可转让的、非独占地和非商业的合法使用“UPUPOO”软件及相关服务的权利。本协议未明示授权的其他一切权利仍由公司保留，您在行使该些权利前须另行获得公司的书面许可，同时公司如未行使前述任何权利，并不构成对该权利的放弃。</p>
        <p>2.7 您无需注册即可开始使用“UPUPOO”软件及相关服务。同时，您也理解，为使您更好地使用“UPUPOO”软件及相关服务，保障您的账号安全，某些功能和/或某些单项服务项目（如跟帖评论服务等）将要求您按照国家相关法律法规的规定，提供真实的身份信息实名注册并登陆后方可使用。</p>
        <p>2.8 UPUPOO的部分服务是以收费方式提供的，如您使用收费服务，请遵守相关的协议。</p>
        <p>2.9 UPUPOO可能根据实际需要对收费服务的收费标准、方式进行修改和变更，也可能会对部分免费服务开始收费。前述修改、变更或开始收费前，将在相应服务页面进行通知或公告。如果您不同意上述修改、变更或付费内容，则应停止使用该服务。</p>
        <p>2.10 在UPUPOO降低收费服务的收费标准或者将收费服务改为免费服务提供时，保留不对原付费用户提供退费或者费用调整之权利。</p>
        <p class="protocal-title-bold">3、关于“账号”</p>
        <p>3.1 “UPUPOO”软件及相关服务为您提供了注册通道，您有权选择合法且符合公司要求的字符组合作为自己的账号，并自行设置符合安全要求的密码。用户设置的账号、密码是用户用以登录并以注册用户身份使用“UPUPOO”软件及相关服务的凭证。</p>
        <p>3.2 您理解并承诺，您所设置的账号不得违反国家法律法规及公司的相关规则，您的账号名称、头像和简介等注册信息及其他个人信息中不得出现违法和不良信息，未经他人许可不得用他人名义（包括但不限于冒用他人姓名、名称、字号、头像等足以让人引起混淆的方式）开设账号，不得恶意注册“UPUPOO”账号（包括但不限于频繁注册、批量注册账号等行为）。您在账号注册及使用过程中需遵守相关法律法规，不得实施任何侵害国家利益、损害其他第三方合法权益，有害社会道德风尚的行为。公司有权对您提交的注册信息进行审核。</p>
        <p>3.3 您的账号仅限于您本人使用，未经公司书面同意，禁止以任何形式赠与、借用、出租、转让、售卖或以其他方式许可他人使用该账号。如果公司发现或者有合理理由认为使用者并非账号初始注册人，公司有权在未通知您的情况下，暂停或终止向该注册账号提供服务，并注销该账号。</p>
        <p>3.4 您有责任维护个人账号、密码的安全性与保密性，并对您以注册账号名义所从事的活动承担全部法律责任，包括但不限于您在“UPUPOO”软件及相关服务上进行的任何数据修改、信息发布、款项支付等操作行为可能引起的一切法律责任。您应高度重视对账号与密码的保密，在任何情况下不向他人透露账号及密码。若发现他人未经许可使用您的账号或发生其他任何安全漏洞问题时，您应当立即通知公司。</p>
        <p>3.5 您的账号为您自行设置并由您保管，账号因您主动泄露或遭受他人攻击、诈骗等行为（包括手机号码丢失）导致的损失及后果，均由您自行承担。收费服务随该账号的丢失而丢失，UPUPOO无权将该账号的收费服务转移至其他账号。
        </p>
        <p>3.6 在丢失账号或遗忘密码时，您可遵照公司的申诉途径及时申诉请求找回账号或密码。您理解并认可，公司的密码找回机制仅需要识别申诉单上所填资料与系统记录资料具有一致性，而无法识别申诉人是否系真正账号有权使用者。公司特别提醒您应妥善保管您的账号和密码。当您使用完毕后，应安全退出。如因您保管不当等自身原因或其他不可抗因素导致遭受盗号或密码丢失，您将自行承担相应责任。</p>
        <p>3.7 在注册、使用和管理账号时，您应保证注册账号时填写的身份信息的真实性、有效性，请您在注册、管理账号时使用真实、准确、合法、有效的相关身份证明材料及必要信息（包括您的姓名及电子邮件地址、联系电话、联系地址等）。依照国家相关法律法规的规定，为使用“UPUPOO”软件及相关服务的部分功能，您需要填写真实的身份信息，请您按照相关法律规定完成实名认证，并注意及时更新上述相关信息。若您提交的材料或提供的信息不准确、不真实、不合法或者公司有理由怀疑为错误、不实或不合法的资料，则公司有权拒绝为您提供相关功能，您可能无法使用“UPUPOO”软件及相关服务或在使用过程中部分功能受到限制。</p>
        <p>3.8 除自行注册“UPUPOO”账号外，用户也可授权使用其合法拥有的包括但不限于公司和/或其关联公司、控制公司其他软件用户账号，以及实名注册的第三方软件或平台用户账号注册并登录使用“UPUPOO”软件及相关服务，但第三方软件或平台对此有限制或禁止的除外。当用户以前述已有账号登录使用的，应保证相应账号已进行实名注册登记，并同样适用本协议中的相关条款。</p>
        <p>3.9 您理解并同意，除您登录、使用“UPUPOO”软件及相关服务外，您还可以用“UPUPOO”账号登录使用公司及其关联公司、控制公司提供的其他软件、服务。您以“UPUPOO”账号登陆并使用前述服务时，将受到前述服务的实际提供方的《用户协议》及其他协议条款约束。</p>
        <p>3.10 为提高您内容曝光量及发布效率，您同意您在本软件/网站的账号及相应账号所发布的全部内容均授权本公司以您的账号自动同步发布至公司及/或关联公司及/或控制公司运营的系列客户端软件及网站。您在本软件/网站发布、修改、删除内容的操作，均会同步到上述系列客户端软件及网站。 您通过已注册或者已同步的账号登录公司及/或关联公司及/或控制公司运营的系列客户端软件产品及网站时（如有），应遵守该客户端软件产品及网站自身的《用户协议》及其他协议条款的规定。</p>
        <p>3.11 您理解并同意，为了充分使用账号资源，如您在注册后未及时进行初次登录使用或连续超过两个月未登录账号使用等情形，公司有权收回您的账号。</p>
        <p class="protocal-title-bold">4、用户个人信息保护</p>
        <p>4.1 公司与您一同致力于您个人信息（即能够独立或与其他信息结合后识别用户身份的信息）的保护，保护用户个人信息是公司的基本原则之一。在使用“UPUPOO”软件及相关服务的过程中，您可能需要提供您的个人信息（包括但不限于您的姓名、电话号码、位置信息、设备信息等），以便公司向您提供更好的服务和相应的技术支持。您理解并同意，公司有权在遵守法律法规、本协议以及《隐私协议》的前提下，获取、使用、储存和分享您的个人信息。</p>
        <p>4.2 公司将运用加密技术、匿名化处理等其他与“UPUPOO”软件及相关服务相匹配的技术措施及其他安全措施保护您的个人信息，防止您的信息被不当使用或被未经授权的访问、使用或泄漏，并为前述目的建立完善的管理制度。</p>
        <p>4.3您可随时浏览、修改自己提交的个人身份信息。您理解并同意，出于安全性和身份识别（如账号或密码找回申诉服务等）的考虑，您可能无法修改注册时提供的初始注册信息及其他验证信息。您也可以申请注销账号，公司会在完成个人身份、安全状态、设备信息等合理和必要的验证后协助您注销账号，并依照您的要求删除有关您账号的一切信息，法律法规另有规定的除外。</p>
        <p>4.4 未经您本人允许，我们不会向任何第三方共享、转让、公开披露您的个人信息，下列情形除外：</p>
        <p>（1）事先获得您的明确授权同意；</p>
        <p>（2）您自行向第三方共享、转让、公开的；</p>
        <p>（3） 与国家安全、国防安全、公共安全、公共卫生、公共利益直接相关的；</p>
        <p>（4）根据适用的法律法规、法规程序的要求、强制性的行政司法要求所必须的情况下进行披露或提供，或与犯罪侦查、起诉、审判和判决执行等直接相关的；我们会依据所要求的个人信息类型和披露方式公开披露您的个人信息。在符合法律法规的前提下，当我们收到上述披露信息的请求时，我们会要求必须出具与之相应的法律文件，如传票或调查函。我们将对所有的请求都进行了审慎的审查，以确保其具备合法依据，且仅限于行政、司法部门因特定调查目的有合法权利获取的数据；</p>
        <p>（5）在法律法规允许的范围内，为维护“UPUPOO”其他用户、公司及其关联公司、控制公司的生命、财产等合法权益或维权产品或服务的安全稳定运行所必需的，例如查找、预防、处理欺诈等违法活动和减少信用风险等；不过这并不包括违反本隐私协议中所做的承诺而为获利目的对外公开或提供个人信息；</p>
        <p>（6）从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；</p>
        <p>（7）法律法规规定的其他情形。</p>
        <p>4.5 在您使用“UPUPOO”软件及相关服务的过程中，公司承诺不会在未经您明确授权同意前，收集或使用您各类财产账户、银行卡、信用卡、第三方支付账户及对应密码等重要资料。您理解并同意，对因您的自身原因引起前述重要资料的泄露以及可能由此带来的任何损失，由您自行承担。</p>
        <p>4.6 考虑到“UPUPOO”软件及相关服务中可能包括或链接至第三方提供的信息或其他服务（包括网站）。您知悉并理解，运营该等服务的第三方可能会要求您提供个人信息。公司特别提醒您，需要认真阅读该等第三方的用户协议、隐私协议以及其他相关的条款，妥善保护自己的个人信息，仅在必要的情况下向该等第三方提供。本协议（以及其他与“UPUPOO”软件及相关服务相关的协议和规则（包括但不限于《隐私协议》）不适用于任何第三方提供的服务，公司对任何因第三方使用由您提供的个人信息所可能引起的后果不承担任何法律责任。</p>
        <p>更多关于用户个人信息保护的内容，请参看《隐私协议》。</p>
        <p class="protocal-title-bold">5、 用户行为规范</p>
        <p>5.1 用户行为要求</p>
        <p>您应当对您使用本产品及相关服务的行为负责，除非法律允许或者经公司事先书面许可，您使用“UPUPOO”软件及相关服务不得具有下列行为：</p>
        <p>5.1.1 使用未经公司授权或许可的任何插件、外挂、系统或第三方工具对“UPUPOO”软件及相关服务的正常运行进行干扰、破坏、修改或施加其他影响。</p>
        <p>5.1.2 利用或针对“UPUPOO”软件及相关服务进行任何危害计算机网络安全的行为，包括但不限于：</p>
        <p>（1）非法侵入他人网络、干扰他人网络正常功能、窃取网络数据等危害网络安全的活动；</p>
        <p>（2）提供专门用于从事侵入网络、干扰网络正常功能及防护措施、窃取网络数据等危害网络安全活动的程序、工具；&nbsp;</p>
        <p>（3）明知他人从事危害网络安全的活动的，为其提供技术支持、广告推广、支付结算等帮助；</p>
        <p>（4）使用未经许可的数据或进入未经许可的服务器/账号；</p>
        <p>（5）未经允许进入公众计算机网络或者他人计算机系统并删除、修改、增加存储信息；</p>
        <p>（6）未经许可，企图探查、扫描、测试“UPUPOO”系统或网络的弱点或其它实施破坏网络安全的行为；</p>
        <p>（7）企图干涉、破坏“UPUPOO”系统或网站的正常运行，故意传播恶意程序或病毒以及其他破坏干扰正常网络信息服务的行为；</p>
        <p>（8）伪造TCP/IP数据包名称或部分名称。&nbsp;</p>
        <p>（9） 对“UPUPOO”软件及相关服务进行反向工程、反向汇编、编译或者以其他方式尝试发现本软件的源代码。</p>
        <p>（10） 恶意注册“UPUPOO”软件及相关服务的账号，包括但不限于频繁、批量注册账号。</p>
        <p>（11） 违反法律法规、本协议、公司的相关规则及侵犯他人合法权益的其他行为。</p>
        <p>5.1.3 在任何情况下，如果公司有理由认为您的任何行为违反或可能违反上述约定的，公司可独立进行判断并处理，且有权在不进行任何事先通知的情况下终止向您提供服务，并追究相关法律责任。</p>
        <p>5.2 信息内容规范</p>
        <p>5.2.1 您按规定完成账号注册和登录后，可以以注册账号或“UPUPOO”合作平台账号登录“UPUPOO”发布内容、跟帖评论等。</p>
        <p>5.2.2 公司致力使跟帖评论成为文明、理性、友善、高质量的意见交流。在推动跟帖评论业务发展的同时，不断加强相应的信息安全管理能力，完善跟帖评论自律，切实履行社会责任，遵守国家法律法规，尊重公民合法权益，尊重社会公序良俗。</p>
        <p>5.2.3 您评论、发布、传播的内容应自觉遵守宪法法律、法规、遵守公共秩序，尊重社会公德、社会主义制度、国家利益、公民合法权益、道德风尚和信息真实性等要求。您同意并承诺不制作、复制、发布、传播法律、行政法规禁止的下列信息内容：&nbsp;</p>
        <p>（1）反对宪法确定的基本原则的；</p>
        <p>（2）危害国家安全，泄露国家秘密的；</p>
        <p>（3）颠覆国家政权，推翻社会主义制度、煽动分裂国家、破坏国家统一的；</p>
        <p>（4）损害国家荣誉和利益的；</p>
        <p>（5）宣扬恐怖主义、极端主义的；</p>
        <p>（6）宣扬民族仇恨、民族歧视，破坏民族团结的；</p>
        <p>（7）煽动地域歧视、地域仇恨的；</p>
        <p>（8）破坏国家宗教政策，宣扬邪教和迷信的；</p>
        <p>（9）编造、散布谣言、虚假信息，扰乱经济秩序和社会秩序、破坏社会稳定的；</p>
        <p>（10）散布、传播暴力、淫秽、色情、赌博、凶杀、恐怖或者教唆犯罪的；</p>
        <p>（11）侵害未成年人合法权益或者损害未成年人身心健康的；</p>
        <p>（12）未获他人允许，偷拍、偷录他人，侵害他人合法权利的；</p>
        <p>（13）包含恐怖、暴力血腥、高危险性、危害表演者自身或他人身心健康内容的；</p>
        <p>（14）危害网络安全、利用网络从事危害国家安全、荣誉和利益的；</p>
        <p>（15）侮辱或者诽谤他人，侵害他人合法权益的；</p>
        <p>（16）对他人进行暴力恐吓、威胁，实施人肉搜索的；</p>
        <p>（17）涉及他人隐私、个人信息或资料的；</p>
        <p>（18）散布污言秽语，损害社会公序良俗的；</p>
        <p>（19）侵犯他人隐私权、名誉权、肖像权、知识产权等合法权益内容的；</p>
        <p>（20）散布商业广告，或类似的商业招揽信息、过度营销信息及垃圾信息；</p>
        <p>（21）使用本网站常用语言文字以外的其他语言文字评论的；</p>
        <p>（22）与所评论的信息毫无关系的；</p>
        <p>（23）所发表的信息毫无意义的，或刻意使用字符组合以逃避技术审核的；</p>
        <p>（24）其他违反法律法规、政策及公序良俗、干扰“UPUPOO”正常运营或侵犯其他用户或第三方合法权益内容的其他信息。</p>
        <p>5.3 公司设立公众投诉、举报平台，您可按照公司公示的投诉举报制度向公司投诉、举报各类违法违规行为、违法传播活动、违法有害信息等内容，公司将及时受理和处理您的投诉举报，以共同营造风清气正的网络空间。</p>
        <p class="protocal-title-bold">6、“UPUPOO”信息内容的使用规范</p>
        <p>6.1 未经公司书面许可，您不得自行或授权、允许、协助任何第三人对本协议“UPUPOO”软件及相关服务中信息内容进行如下行为：</p>
        <p>（1）复制、读取、采用“UPUPOO”软件及相关服务的信息内容，用于包括但不限于宣传、增加阅读量、浏览量等商业用途；</p>
        <p>（2）擅自编辑、整理、编排“UPUPOO”软件及相关服务的信息内容后在“UPUPOO”软件及相关服务的源页面以外的渠道进行展示；</p>
        <p>（3）采用包括但不限于特殊标识、特殊代码等任何形式的识别方法，自行或协助第三人对“UPUPOO”软件及相关服务的信息内容产生流量、阅读量引导、转移、劫持等不利影响；</p>
        <p>（4）其他非法获取或使用“UPUPOO”软件及相关服务的信息内容的行为。</p>
        <p>6.2 经公司书面许可后，您对“UPUPOO”软件及相关服务的信息内容的分享、转发等行为，还应符合以下规范：</p>
        <p>（1）对抓取、统计、获得的相关搜索热词、命中率、分类、搜索量、点击率、阅读量等相关数据，未经公司事先书面同意，不得将上述数据以任何方式公示、提供、泄露给任何第三人；</p>
        <p>（2）不得对“UPUPOO”软件及相关服务的源网页进行任何形式的任何改动，包括但不限于“UPUPOO”软件及相关服务的首页（profile页面）链接，广告系统链接等入口，也不得对“UPUPOO”软件及相关服务的源页面的展示进行任何形式的遮挡、插入、弹窗等妨碍；&nbsp;</p>
        <p>（3）应当采取安全、有效、严密的措施，防止“UPUPOO”软件及相关服务的信息内容被第三方通过包括但不限于“蜘蛛（spider）”程序等任何形式进行非法获取；</p>
        <p>（4）不得把相关数据内容用于公司书面许可范围之外的目的，进行任何形式的销售和商业使用，或向第三方泄露、提供或允许第三方为任何方式的使用；</p>
        <p>（5）向任何第三人分享、转发、复制“UPUPOO”软件及相关服务信息内容的行为，应当遵守公司为此制定的其他规范和标准。</p>
        <p class="protocal-title-bold">7、违约处理</p>
        <p>7.1 针对您违反本协议或其他服务条款的行为，公司有权独立判断并视情况采取预先警示、拒绝发布、立即停止传输信息、删除跟帖、短期禁止发言、限制账号部分或者全部功能直至永久关闭账号等措施。公司有权公告处理结果，且有权根据实际情况决定是否恢复相关账号的使用。对涉嫌违反法律法规、涉嫌违法犯罪的行为将保存有关记录，并依法向有关主管部门报告、配合有关主管部门调查。</p>
        <p>7.2 因您违反本协议或其他服务条款规定，引起第三方投诉或诉讼索赔的，您应当自行处理并承担全部可能由此引起的法律责任。因您的违法或违约行为导致公司及其关联公司、控制公司向任何第三方赔偿或遭受国家机关处罚的，您还应足额赔偿公司及其关联公司、控制公司因此遭受的全部损失。</p>
        <p>7.3 公司尊重并保护法人、公民的知识产权、名誉权、姓名权、隐私权等合法权益。您保证，在使用“UPUPOO”软件及相关服务时上传的文字、图片、视频、音频、链接等不侵犯任何第三方的知识产权、名誉权、姓名权、隐私权等权利及合法权益。否则，公司有权在收到权利方或者相关方通知的情况下移除该涉嫌侵权内容。针对第三方提出的全部权利主张，您应自行处理并承担全部可能由此引起的法律责任；如因您的侵权行为导致公司及其关联公司、控制公司遭受损失的（包括经济、商誉等损失），您还应足额赔偿公司及其关联公司、控制公司遭受的全部损失。</p>
        <p class="protocal-title-bold">8、服务的变更、中断和终止</p>
        <p>8.1 您理解并同意，公司提供的“UPUPOO”软件及相关服务是按照现有技术和条件所能达到的现状提供的。公司会尽最大努力向您提供服务，确保服务的连贯性和安全性。您理解，公司不能随时预见和防范技术以及其他风险，包括但不限于不可抗力、病毒、木马、黑客攻击、系统不稳定、第三方服务瑕疵及其他各种安全问题的侵扰等原因可能导致的服务中断、数据丢失以及其他的损失和风险。</p>
        <p>8.2 您理解并同意，公司为了服务整体运营的需要，有权在公告通知后修改、中断、中止或终止“UPUPOO”软件及相关服务，而无须向您负责或承担任何赔偿责任。</p>
        <p>8.3 UPUOO有权按本协议2.8条的约定进行收费。若您未按时足额付费，UPUPOO有权中断、中止或终止提供服务。</p>
        <p class="protocal-title-bold">9、广告</p>
        <p>9.1 您理解并同意，在使用“UPUPOO”软件及相关服务过程中公司可能会向您推送具有相关性的信息、广告发布或品牌推广服务，且公司有权在“UPUPOO”软件及相关服务中展示“UPUPOO”软件及相关服务相关和/或第三方供应商、合作伙伴的商业广告、推广或信息（包括商业或非商业信息）。</p>
        <p>9.2 公司依照法律规定履行广告及推广相关义务，您应当自行判断该广告或推广信息的真实性和可靠性并为自己的判断行为负责。除法律法规明确规定外，您因该广告或推广信息进行的购买、交易或因前述内容遭受的损害或损失由您自行承担，公司不予承担责任。</p>
        <p class="protocal-title-bold">10、知识产权</p>
        <p>认识到保护知识产权对创作者的重要性，UPUPOO将保护知识产权作为运营的基本原则之一。</p>
        <p>10.1.您理解并同意，在使用“UPUPOO”软件及相关服务时发布上传的文字、图片、视频、音频等均由您原创或已获合法授权。您通过“UPUPOO”上传、发布的任何内容的知识产权归属您或原始著作权人所有。</p>
        <p>10.2 为了更好地宣传优秀作品，UPUPOO可能会在网站和客户端应用程序内的推荐位推荐您的作品。UPUPOO可能会在官方微博、官方微信公众号等官方名义的渠道中署名推荐您的作品。除此之外，除非获得单独许可，UPUPOO不会在本产品外的任何宣传活动中使用您的作品作为素材。</p>
        <p>10.3 您通过UPUPOO发表的作品将默认受“署名+原地址+非商业性使用”的转载规则保护，您可以在上传作品时针对不同作品选择合适的转载规则，使用多重措施保护自己的作品。</p>
        <p>10.4 公司在“UPUPOO”软件及相关服务中提供的内容（包括但不限于软件、技术、程序、网页、文字、图片、图像、音频、视频、图表、版面设计、电子文档等）的知识产权属于公司所有。公司提供本服务时所依托的软件的著作权、专利权及其他知识产权均归公司所有。未经公司许可，任何人不得擅自使用（包括但不限于通过任何机器人、蜘蛛等程序或设备监视、复制、传播、展示、镜像、上载、下载）“UPUPOO”软件及相关服务中的内容。</p>
        <p>10.5 您确认并同意授权公司以公司自己的名义或委托专业第三方对侵犯您上传发布的享有知识产权的内容进行代维权，维权形式包括但不限于：监测侵权行为、发送维权函、提起诉讼或仲裁、调解、和解等，公司有权对维权事宜做出决策并独立实施。</p>
        <p>10.6 公司为“UPUPOO”开发、运营提供技术支持，并对“UPUPOO”软件及相关服务的开发和运营等过程中产生的所有数据和信息等享有法律法规允许范围内的全部权利。</p>
        <p>10.7 请您在任何情况下都不要私自使用公司的包括但不限于“UPUPOO”和“upupoo.com”等在内的任何商标、服务标记、商号、域名、网站名称或其他显著品牌特征等（以下统称为“标识”）。未经公司事先书面同意，您不得将本条款前述标识以单独或结合任何方式展示、使用或申请注册商标、进行域名注册等，也不得实施向他人明示或暗示有权展示、使用、或其他有权处理该些标识的行为。由于您违反本协议使用公司上述商标、标识等给公司或他人造成损失的，由您承担全部法律责任。</p>
        <p class="protocal-title-bold">11、免责声明</p>
        <p>11.1 您理解并同意，“UPUPOO”软件及相关服务可能会受多种因素的影响或干扰，公司不保证(包括但不限于)：</p>
        <p>11.1.1 公司完全适合用户的使用要求；</p>
        <p>11.1.2 公司不受干扰，及时、安全、可靠或不出现错误；您经由公司取得的任何软件、服务或其他材料符合用户的期望；</p>
        <p>11.1.3软件中任何错误都将能得到更正。</p>
        <p>11.2 对于涉嫌借款、理财或其他涉财产的网络信息、账户密码、广告或推广等信息的，请您谨慎对待并自行进行判断，基于前述原因您因此遭受的财产、利润、商业信誉、资料损失或其他有形或无形损失，公司不承担任何直接、间接、附带、特别、衍生性或惩罚性的赔偿责任。</p>
        <p>11.3 您理解并同意，在使用“UPUPOO”软件及相关服务过程中，可能遇到不可抗力等因素（不可抗力是指不能预见、不能克服并不能避免的客观事件），包括但不限于政府行为、监管政策、自然灾害、网络原因、黑客攻击、战争或任何其它类似事件。出现不可抗力情况时，公司将努力在第一时间及时修复，但因不可抗力给您造成暂停、中止、终止服务或造成任何损失的，您同意公司不承担任何法律责任</p>
        <p>11.4 公司依据本协议约定获得处理违法违规内容的权利，该权利不构成公司的义务或承诺，公司不能保证及时发现违法行为或进行相应处理。</p>
        <p>11.5 您理解并同意，关于“UPUPOO”软件及相关服务，公司不提供任何种类的明示或暗示担保或条件，包括但不限于商业适售性、特定用途适用性等。您对“UPUPOO”软件及相关服务的使用行为必须自行承担相应风险。</p>
        <p>11.6 您理解并同意，本协议是在保障遵守国家法律法规、维护公序良俗，保护他人合法权益，公司在能力范围内尽最大的努力按照相关法律法规进行判断，但并不保证公司判断完全与司法机关、行政机关的判断一致，如因此产生的后果您已经理解并同意自行承担。</p>
        <p class="protocal-title-bold">12、关于单项服务与第三方服务的特殊约定</p>
        <p>12.1 “UPUPOO”软件及相关服务中包含公司以各种合法方式获取的信息或信息内容链接，同时也包括公司及其关联公司、控制公司合法运营的其他单项服务。这些服务在“UPUPOO”以单独板块形式存在，包括但不限于“壁纸”、“图片”、“视频”、“COS”、“绘画”、“圈子”等。公司有权不时地增加、减少或改动这些单独板块的设置及服务。</p>
        <p>12.2 您可以在“UPUPOO”软件中开启和使用上述单项服务。某些单项服务可能需要您同时接受就该单项服务特别制订的协议或者其他约束您与该单项服务提供者之间的规则。公司将在您计划使用前述单向服务时以醒目的方式向您提供这些协议、规则，供您查阅与同意。一旦您开始使用上述服务，则视为您理解并接受有关单项服务的相关协议、规则的约束。</p>
        <p>12.3 如您可能在“UPUPOO”软件中使用第三方提供的软件及相关服务时，除遵守本协议及本UPUPOO相关规则外，还可能需要遵守第三方的协议、相关规则。如因第三方软件及相关服务产生的争议、损失或损害，由您与第三方自行解决，公司并不就此而对您或任何第三方承担任何责任。</p>
        <p class="protocal-title-bold">13、未成年人使用条款</p>
        <p>13.1 若用户是未满18周岁的未成年人，应在监护人监护、指导并获得监护人同意情况下认真阅读本协议后，方可使用“UPUPOO”软件及相关服务。</p>
        <p>13.2 公司重视对未成年人个人信息的保护，未成年用户在填写个人信息时，请加强个人保护意识并谨慎对待，并在监护人指导时正确使用“UPUPOO”软件及相关服务</p>
        <p>13.3 未成年用户及其监护人理解如因未成年人违反法律法规、本协议内容，则未成年人及其监护人应依照法律规定承担因此而可能引起的全部法律责任。</p>
        <p>13.4未成年人用户特别提示：</p>
        <p>13.4.1 未成年人使用本软件及相关服务应该在其监护人的监督指导下，在合理范围内正确学习使用网络，避免沉迷虚拟的网络空间，养成良好上网习惯。&nbsp;</p>
        <p>13.4.2 未成年人用户必须遵守《全国青少年网络文明公约》：</p>
        <p>（1）要善于网上学习，不浏览不良信息；&nbsp;</p>
        <p>（2）要诚实友好交流，不侮辱欺诈他人；</p>
        <p>（3）要增强自护意识，不随意约会网友；</p>
        <p>（4）要维护网络安全，不破坏网络秩序；</p>
        <p>（5）要有益身心健康，不沉溺虚拟时空。</p>
        <p>13.5 为更好的保护未成年人隐私权益，公司提醒用户慎重发布包含未成年人素材的内容，一经发布，即视为用户同意本软件及相关服务展示未成年人的信息、肖像、声音等，且允许公司依据本协议使用、处理该等与未成年人相关的内容。</p>
        <p class="protocal-title-bold">14、其他</p>
        <p>14.1 本协议的成立、生效、履行、解释及争议的解决均应适用中华人民共和国大陆地区法律。若本协议之任何规定因与中华人民共和国大陆地区的法律抵触而无效或不可执行，则这些条款将尽可能按照接近本协议原条文意旨重新解析，且本协议其它规定仍应具有完整的效力及效果。</p>
        <p>14.2 本协议的签署地点为中华人民共和国武汉市洪山区。若您因本协议与公司发生任何争议，双方应尽量友好协商解决；如协商不成的，您同意应将相关争议提交至武汉市洪山区人民法院诉讼解决。</p>
        <p>14.3 公司有权依据国家政策、技术条件、产品功能等变化需要而进行修改本协议，公司会将修改后的协议予以发布；前述内容一经正式发布，即作为本协议不可分割的组成部分并具有等同于本协议的法律效力。公司会以适当的方式将本协议的修订条款送达您（网站公布、系统通知等），并督促您同意或拒绝，您也可以在网站首页或软件设置页面查阅最新版本的协议条款。</p>
        <p>如您对修改后的协议条款存有异议的，请立即停止登录或使用“UPUPOO”软件及相关服务。若您继续登录或使用“UPUPOO”软件及相关服务，即视为您认可并接受修改后的协议条款。</p>
        <p>14.4 本协议中的标题仅为方便及阅读而设，并不影响本协议中任何规定的含义或解释。</p>
        <p>14.5 您和公司均是独立的主体，在任何情况下本协议不构成公司对用户的任何形式的明示或暗示担保或条件，双方之间亦不构成代理、合伙、合营或雇佣关系。</p>
        <p>14.6 本协议的版权为公司所有，在法律法规允许的范围内，公司保留一切解释和修改的权利。</p>
    </div>
</div>
</template>

<script>
export default {
    name: '',
    components: {},
    data() {
        return {

        };
    },
    computed: {},
    methods: {

    },
}
</script>

<style lang="scss" scoped>
.filePage {
  background: #fff;
  width: 100%;
  height: 100%;

  .h-bga {
    background: none;
    overflow-x: hidden;
  }

  .protocol-head {
    background-color: #fff;
    box-shadow: 0 2px 4px 0 rgba(85, 85, 85, 0.1);
  }

  .protocol-head-container {
    width: 1080px;
    height: 60px;
    margin: 0 auto;
    display: flex;
    align-items: center;
  }

  .protocol-head-container a {
    display: flex;
    align-items: center;
  }

  .protocol-head-container .logo {
    width: 42px;
    height: 42px;
    line-height: 42px;
    margin-right: 10px;
    text-align: center;
    font-size: 0;
    display: inline-block;
  }

  .protocol-head-container img {
    max-width: 100%;
    max-height: 100%;
    vertical-align: middle;
  }

  .protocol-head-container .text {
    font-size: 20px;
    color: #4a4a4a;
    line-height: 60px;
  }

  .protocol-layout-body {
    width: 1080px;
    margin: 22px auto 0;
    min-height: 886px;
    color: #000;
    font-size: 16px;
  }

  .protocol-layout-body h3 {
    padding: 4px 0 24px;
    font-size: 24px;
    border-bottom: 1px solid #e7e7eb;
    margin-bottom: 24px;
  }

  .protocol-layout-body p {
    margin-bottom: 20px;
    line-height: 24px;
  }

  .protocal-title-bold {
    font-weight: bold;
  }
}
</style>
